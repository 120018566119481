export default {
  "About": {
    "since_when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since When?"])},
    "since_when_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I work professionally in the IT sector since 2015"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some information about me"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
    "what_we_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What I Do?"])},
    "what_we_do_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm a devoted front-end engineer specializing in React and its technologies. I'm always attuned to JavaScript's evolution. Collaborating with product owners and designers, I craft tailored interfaces. Active in online communities and many courses, I stay current in computer science trends. Beyond code, I shape immersive user experiences, embracing dynamic technology."])},
    "who_am_i_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seasoned software engineer with over 8 years of immersive IT experience, dedicated to delivering impactful results. My focus lies in crafting polished, user-centric front-end solutions that seamlessly transcend browser boundaries. My passion for problem-solving drives me to excel, continuously adapting to emerging technologies and frameworks. I possess the ability to dissect project requirements, strategically navigating towards the most effective technological pathways."])},
    "who_we_are": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who Am I?"])},
    "why_we_do_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why?"])},
    "why_we_do_it_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The joy of creation always motivates me to the completion of a project."])}
  },
  "Contacts": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All to contact me"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACTS"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write to me"])},
    "your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
    "your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])}
  },
  "Download": {
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "download_our_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download my cv"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspired by nature, follow technology, appreciate the classics. You can build modern & professional websites with Iris. The possibilities are just endless."])}
  },
  "FunFacts": {
    "awards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winning Awards"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients Worked With"])},
    "coffees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cups of coffee drinking"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed Projects"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before anyone is not told"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some Fun Facts"])}
  },
  "Jobs": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section you can find latest jobs post from the market"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])}
  },
  "Join": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow me on social networks"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join me"])}
  },
  "Menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])}
  },
  "Partners": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Those who trust us"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR PARTNERS"])}
  },
  "Prices": {
    "custom_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom domain"])},
    "disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disk"])},
    "multi_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi Use"])},
    "order_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order now"])},
    "personal_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Use"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["projects"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That how much"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRICES"])},
    "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])}
  },
  "Reviews": {
    "slide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"This was my first experience with that team and outperformed my expectation! They know there stuff and I highly recommend them! A+++\"."])},
    "slide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\""])},
    "slide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit\"."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What people say about us"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])}
  },
  "Services": {
    "app_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Development"])},
    "cloud_hosting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud Hosting"])},
    "clouding_pen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coding Pen"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["what we really know how"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "ui_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI Design"])}
  },
  "Slider": {
    "Slide1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An experienced software engineer with strong problem solving skills"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome! I am Myron Apostolakis"])}
    },
    "Slide2": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning new programming languages, frameworks and development methodologies"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constant learner"])}
    },
    "Slide3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating clean, functional, testable, documented and maintainable cross browser front-end solutions"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In touch with the state-of-art technologies"])}
    }
  },
  "Subscribe": {
    "enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])}
  },
  "Team": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meat our experts"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR TEAM"])}
  },
  "Tweets": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent tweets"])}
  },
  "Useful": {
    "links": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "advertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertise"])},
      "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful links"])}
    }
  },
  "Works": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "app_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App development"])},
    "awards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awards"])},
    "dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])},
    "graphics_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphics design"])},
    "plugins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugins"])},
    "responsive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsive"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What I am proud of"])},
    "themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Themes"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "websites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Websites"])},
    "APIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs"])}
  },
  "lorem_ipsum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet set, consectetur utes anet adipisicing elit, sed do eiusmod tempor incidist."])},
  "our_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Skills"])},
  "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more"])}
}