<template>
  <div class="bg-white dark:bg-gray-200">
    <div class="container m-auto">
      <section class="skills p-10 flex justify-start flex-col">
        <div class="header text-2xl font-bold">{{ $t("our_skills") }}</div>
        <section
          class="
            grid grid-cols-2
            mt-10
            gap-x-10 gap-y-5
            align-items-center
            justify-items-between
            progress_bars_skills
            text-center
            lg:grid-cols-1
            md:justify-items-start
          "
        >
          <app-progress-bar title="React" percentage="90%" />
          <app-progress-bar title="Redux" percentage="90%" />
          <app-progress-bar title="Redux-Saga" percentage="90%" />
          <app-progress-bar title="NodeJS" percentage="70%" />
          <app-progress-bar title="Git" percentage="90%" />
          <app-progress-bar
            title="Working With Restful APIs"
            percentage="90%"
          />
          <app-progress-bar title="Jest" percentage="100%" />
          <app-progress-bar title="Cypress & E2E testing" percentage="90%" />
          <app-progress-bar title="WordPress" percentage="60%" />
          <app-progress-bar title="Html & Css" percentage="90%" />
          <app-progress-bar title="Javascript" percentage="90%" />
          <app-progress-bar title="Linux" percentage="60%" />
          <app-progress-bar title="MySQL" percentage="60%" />
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import AppProgressBar from "@/shared/progress_bars/ProgressBar.vue";
export default {
  name: "Skills",
  components: {
    AppProgressBar,
  },
};
</script>

<style scoped></style>
