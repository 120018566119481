export const social_networks = [
  {
    id: 1,
    icon: "facebook-f",
    url: "https://www.facebook.com/myapos/",
  },
  {
    id: 2,
    icon: "twitter",
    url: "https://twitter.com/myapost",
  },
  {
    id: 3,
    icon: "linkedin",
    url: "https://www.linkedin.com/in/myapos/",
  },
];
