<template>
  <div class="flex flex-col">
    <div class="text-2xl text-left my-2">{{ $t("Contacts.write") }}</div>

    <vee-form
      :validation-schema="schema"
      @submit="submit"
      class="flex flex-col"
    >
      <vee-field
        type="text"
        name="name"
        class="
          mt-2
          p-2
          opacity-50
          focus:outline-none focus:border-black
          bg-gray-900
          placeholder-white
          w-full
        "
        :placeholder="$t('Contacts.your_name')"
      />
      <ErrorMessage class="text-red-600 text-left" name="name" />
      <vee-field
        type="email"
        name="email"
        class="
          mt-2
          p-2
          opacity-50
          focus:outline-none focus:border-black
          bg-gray-900
          placeholder-white
          w-full
        "
        :placeholder="$t('Contacts.email')"
      />
      <ErrorMessage class="text-red-600 text-left" name="email" />
      <vee-field
        type="text"
        name="subject"
        class="
          mt-2
          p-2
          opacity-50
          focus:outline-none focus:border-black
          bg-gray-900
          placeholder-white
          w-full
        "
        :placeholder="$t('Contacts.subject')"
      />
      <ErrorMessage class="text-red-600 text-left" name="subject" />

      <vee-field
        type="textarea"
        as="textarea"
        name="message"
        :rows="num_of_rows"
        :cols="num_of_cols"
        class="
          mt-2
          p-2
          opacity-50
          focus:outline-none focus:border-black
          bg-gray-900
          placeholder-white
          w-full
        "
        :placeholder="$t('Contacts.your_message')"
      />
      <ErrorMessage class="text-red-600 text-left" name="message" />
      <vee-field
        type="number"
        name="captcha"
        class="
          mt-2
          p-2
          opacity-50
          focus:outline-none focus:border-black
          bg-gray-900
          placeholder-white
          w-full
        "
        placeholder="Please type the number that you see below"
      />
      <div
        class="
          bg-white
          text-gray-900
          w-1/4
          h-2/4
          mx-auto
          flex flex-col
          items-center
          justify-center
          m-2
          rounded
        "
      >
        <div>Captcha</div>
        <div class="glow">{{ captcha }}</div>
      </div>
      <ErrorMessage class="text-red-600 text-left" name="captcha" />
      <button
        type="submit"
        :disabled="submitted"
        class="
          block
          bg-gray-900
          text-white
          py-1.5
          px-3
          transition
          hover:bg-gray-600
          mt-2
          w-full
        "
      >
        {{ isSubmitting ? $t("Contacts.sending") : $t("Contacts.send") }}
      </button>
      <div class="bg-green-400 mt-4 w-full" v-if="submitted">Email sent</div>
      <app-modal
        :modalIsOpen="modalIsOpen"
        :closeModal="closeModal"
        :openModal="openModal"
        :modalTitle="modalTitle"
        :modalContent="modalContent"
        :closeBtn="closeBtn"
      />
    </vee-form>
  </div>
</template>

<script>
import AppModal from "@/components/modal/Modal";
import axios from "axios";

export default {
  name: "ContactForm",
  components: { AppModal },
  data() {
    return {
      schema: {
        email: "required|min:3|max:100|email",
        name: "required|min:3|max:100",
        subject: "required|min:3|max:100",
        message: "required|min:3|max:100",
        captcha: "required|numeric",
      },
      num_of_cols: this.handleResize().num_of_cols,
      num_of_rows: this.handleResize().num_of_rows,
      captcha: Math.floor(Math.random() * 1000),
      submitted: false,
      modalIsOpen: false,
      modalTitle: "Notification",
      modalContent: "",
      closeBtn: "OK",
      isSubmitting: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    submit(values) {
      const { captcha, ...restValues } = values;
      if (parseInt(captcha) == this.captcha) {
        this.isSubmitting = true;
        axios
          .post("/.netlify/functions/sendContactEmail/sendContactEmail", {
            ...restValues,
          })
          .then((response) => {
            console.log(response);
            this.submitted = true;
            this.isSubmitting = false;
          })
          .catch((error) => {
            console.log(error);
            this.isSubmitting = false;
          });
      } else {
        this.modalContent = "Please check your captcha input and try again";
        this.modalIsOpen = true;
        this.captcha = Math.floor(Math.random() * 1000);
      }
    },
    handleResize() {
      let num_of_cols = 60;
      let num_of_rows = 4;
      if (screen.width < 1024) {
        num_of_cols = 40;
        num_of_rows = 3;
      }
      if (screen.width < 728) {
        num_of_cols = 20;
        num_of_rows = 2;
      }

      if (screen.width < 545) {
        num_of_cols = 1;
        num_of_rows = 1;
      }

      this.num_of_cols = num_of_cols;
      this.num_of_rows = num_of_rows;

      return { num_of_cols, num_of_rows };
    },
    closeModal() {
      this.modalIsOpen = false;
    },
    openModal() {
      this.modalIsOpen = true;
    },
  },
};
</script>

<style scoped>
.row {
  margin: 2px;
  font-size: 0.8rem;
}
button.submitting::after {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: calc(50% - 0.5rem);
  left: 1.5rem;
  border-radius: 2em;
  border-color: transparent transparent black black;
  border-style: solid;
  border-width: 0.15em;
  animation: spinner-rotation 0.75s infinite;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.glow {
  font-size: 40px;
  color: #fff;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes spinner-rotation {
  to {
    transform: rotate(360deg);
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
      0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}
</style>
