<template>
  <div class="row">
    <div class="font-bold">{{ title }}</div>
    <div class="progress">
      <div class="progress_bar" :style="{ width: percentage }">
        {{ percentage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    title: String,
    percentage: String,
  },
};
</script>

<style scoped>
.progress {
  border: 3px solid #363940;
  background-color: transparent;
  overflow: visible;
  height: auto;
  min-width: 500px;
}

.progress_bar {
  background-color: #676d75;
}

@media screen and (max-width: 1023px) {
  .progress {
    min-width: 450px;
  }
}

@media screen and (max-width: 640px) {
  .progress {
    min-width: 300px;
  }
}
@media screen and (max-width: 450px) {
  .progress {
    min-width: 250px;
  }
}
</style>
