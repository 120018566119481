<template>
  <span
    v-for="link in links"
    :key="link.id"
    class="dark:text-gray-100 mr-2 text-xs"
  >
    <a :href="link.target" target="_blank">{{ $t(link.description) }}</a>
  </span>
</template>

<script>
export default {
  name: "LinksList",
  props: {
    links: Object,
  },
};
</script>

<style scoped></style>
