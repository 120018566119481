export default {
  "About": {
    "since_when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απο ποτε?"])},
    "since_when_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δραστηριοποιούμαι επαγγελματικά στον χώρο της πληροφορίκής από το 2015"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λιγες πληροφοριες για εμενα"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σχετικα"])},
    "what_we_do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τι κανω?"])},
    "what_we_do_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είμαι αφοσιωμένος μηχανικός front-end, εξειδικευμένος σε React και σχετικές τεχνολογίες. Διατηρώ επίγνωση της δυναμικής εξέλιξης του οικοσυστήματος της JavaScript. Μέσα από συνεργασίες με ιδιοκτήτες προϊόντων και σχεδιαστές, δημιουργώ προσαρμοσμένες διεπαφές που ανταποκρίνονται στις απαιτήσεις των πελατών. Η συμμετοχή μου σε διαδικτυακές κοινότητες και η ολοκλήρωση πληθώρας διαδικτυακών μαθημάτων με κρατούν ενήμερο για τις τελευταίες τάσεις στην επιστήμη των υπολογιστών. Εκτός από τον κώδικα, διαμορφώνω συναρπαστικές εμπειρίες χρηστών, αξιοποιώντας τη δυναμική της τεχνολογίας."])},
    "who_am_i_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηχανικός λογισμικού με 8+ χρόνια εμπειρίας στον τομέα της πληροφορικής, που ειδικεύεται στη δημιουργία καθαρών, λειτουργικών και συντηρήσιμων λύσεων διεπαφής μεταξύ προγραμμάτων περιήγησης. Με ισχυρές δεξιότητες επίλυσης προβλημάτων, με έφεση στην εκμάθηση νέων τεχνολογιών και την ανάλυση των απαιτήσεων του έργου για τον προσδιορισμό της βέλτιστης τεχνολογικής προσέγγισης."])},
    "who_we_are": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιος ειμαι?"])},
    "why_we_do_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γιατι?"])},
    "why_we_do_it_descr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η χαρα της δημιουργιας παντα με ωθει προς την ολοκληρωση ενος εργου"])}
  },
  "Contacts": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ηλεκτρονικη διευθυνση"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καταχωρηστε το email σας"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολη"])},
    "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστελλεται..."])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θεμα"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεια επαφης"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικοινωνια"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γραψτε μου"])},
    "your_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μηνυμα σας"])},
    "your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ονομα σας"])}
  },
  "Download": {
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφορτωση"])},
    "download_our_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφορτωστε το cv μου"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφορτωστε το cv μου"])}
  },
  "FunFacts": {
    "awards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βραβεια"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πελατες εχουν δουλεψει μαζι μας"])},
    "coffees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["κουπες καφε χρειαστηκαν"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκληρωμενα εργα"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Που θα μαθετε πρωτοι"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καποια διασκεδαστικα γεγονοτα"])}
  },
  "Jobs": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε αυτη την ενοτητα μπορειτε να ενημερωθειτε για τις τελευταιες δημοσιευσεις αγγελιων στην αγορα"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εργασια"])}
  },
  "Join": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακολουθηστε με στα κοινωνικα μεσα δικτυωσης"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βρειτε με"])}
  },
  "Menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχετικα"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επικοινωνια"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κεντρικη"])},
    "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εργασια"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμες"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπηρεσιες"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ομαδα"])},
    "works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εργα"])}
  },
  "Partners": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οσοι μας εμπιστευονται"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι συνεργατες μας"])}
  },
  "Prices": {
    "custom_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσαρμογή domain"])},
    "disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δισκος"])},
    "multi_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολλων χρησεων"])},
    "order_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παραγγειλε!"])},
    "personal_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσωπικη χρηση"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εργα"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πακετα προσφορων"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποστηριξη"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμες"])},
    "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απεριοριστα"])}
  },
  "Reviews": {
    "slide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή ήταν η πρώτη μου εμπειρία με αυτήν την ομάδα και ξεπέρασε τις προσδοκίες μου! Ξέρουν εκεί πράγματα και τους προτείνω ανεπιφύλακτα! Α+++"])},
    "slide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κείμενο Lorem Ipsum χρησιμοποιείται για να γεμίσει χώρους που έχουν οριστεί για να φιλοξενήσουν κείμενα που δεν έχουν ακόμη δημοσιευτεί. Χρησιμοποιούν προγραμματιστές, γραφίστες, τυπογράφους για να πάρουν μια πραγματική εντύπωση για το ψηφιακό / διαφημιστικό / συντακτικό προϊόν στο οποίο εργάζονται."])},
    "slide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κείμενο Lorem Ipsum χρησιμοποιείται για να γεμίσει χώρους που έχουν οριστεί για να φιλοξενήσουν κείμενα που δεν έχουν ακόμη δημοσιευτεί. Χρησιμοποιούν προγραμματιστές, γραφίστες, τυπογράφους για να πάρουν μια πραγματική εντύπωση για το ψηφιακό / διαφημιστικό / συντακτικό προϊόν στο οποίο εργάζονται."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τι λεει ο κοσμος για εμας"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κριτικες"])}
  },
  "Services": {
    "app_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάπτυξη εφαρμογών"])},
    "cloud_hosting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φιλοξενία σε cloud"])},
    "clouding_pen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείγματα κώδικα"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αυτό που πραγματικά ξέρουμε"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπηρεσιες"])},
    "ui_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχεδίαση UI"])}
  },
  "Slider": {
    "Slide1": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Εμπειρος μηχανικός λογισμικού με ισχυρές δεξιότητες επίλυσης προβλημάτων"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καλως ηρθατε! Ονομάζομαι Μυρων Αποστολακης"])}
    },
    "Slide2": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκμάθηση νέων γλωσσών προγραμματισμού, πλαισίων και μεθοδολογιών ανάπτυξης"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεχής εκπαιδευόμενος"])}
    },
    "Slide3": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία καθαρών, λειτουργικών, ελεγχόμενων, τεκμηριωμένων και συντηρήσιμων λύσεων διεπαφής μεταξύ προγραμμάτων περιήγησης"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε επαφή με τις τεχνολογίες αιχμής"])}
    }
  },
  "Subscribe": {
    "enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καταχωρηστε το email σας"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολη"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφη"])}
  },
  "Team": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συναντηθειτε με τους ειδικους μας"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ομαδα μας"])}
  },
  "Tweets": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσφατα tweets"])}
  },
  "Useful": {
    "links": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχετικα"])},
      "advertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαφημιση"])},
      "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πνευματικη ιδιοκτησια"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νομικα"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τυπος"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υπηρεσιες"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρησιμοι συνδεσμοι"])}
    }
  },
  "Works": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολα"])},
    "app_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναπτυξη εφαρμογων"])},
    "awards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βραβεια"])},
    "dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιβαλλοντα διαχειρισης"])},
    "graphics_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχεδιαση γραφικων"])},
    "plugins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθετα"])},
    "responsive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσαρμοζομενος σχεδιασμος σε συσκευες"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για αυτα που ειμαι υπερηφανος"])},
    "themes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θεματα"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εργα"])},
    "websites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστοσελιδες"])},
    "APIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs"])}
  },
  "lorem_ipsum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το κείμενο Lorem Ipsum χρησιμοποιείται για να γεμίσει χώρους που έχουν οριστεί για να φιλοξενήσουν κείμενα που δεν έχουν ακόμη δημοσιευτεί. Χρησιμοποιούν προγραμματιστές, γραφίστες, τυπογράφους για να πάρουν μια πραγματική εντύπωση για το ψηφιακό / διαφημιστικό / συντακτικό προϊόν στο οποίο εργάζονται."])},
  "our_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι δεξιοτητες μου"])},
  "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δες περισσοτερα"])}
}