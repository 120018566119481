<template>
  <div
    class="
      subsection
      flex flex-row
      content-center
      items-center
      justify-center
      pt-2
    "
  >
    <hr class="ruler mix-blend-exclusion" />
    <div class="p-3"><mdicon :name="name" /></div>
    <hr class="ruler mix-blend-exclusion" />
  </div>
</template>

<script>
export default {
  name: "ruler",
  props: {
    name: String,
  },
};
</script>

<style scoped>
.ruler {
  max-width: 200px;
  height: 2px;
  width: 200px;
  border-top: 1px solid #ccc;
}

@media only screen and (max-width: 640px) {
  .ruler {
    width: 100px;
  }
}
</style>
