export const data = {
  links: [
    {
      id: 1,
      target: "https://www.google.com/",
      description: "Google",
    },
    {
      id: 2,
      target: "https://github.com/myapos",
      description: "Github",
    },
    {
      id: 3,
      target: "https://ma.ellak.gr/",
      description: "ELLAK",
    },
    {
      id: 4,
      target: "https://reactjs.org/",
      description: "React",
    },
    {
      id: 5,
      target: "https://redux.js.org/",
      description: "Redux",
    },
    {
      id: 6,
      target: "https://www.cypress.io/",
      description: "Cypress",
    },
  ],
  tweets: [
    {
      by: "myron",
      text: "Excepteur sint occaecat cupidatat non proident",
      when: "1 Hour ago",
    },
    {
      by: "myron",
      text: "Excepteur sint occaecat cupidatat non proident",
      when: "2 Hours ago",
    },
  ],
  email: "example@example.com",
};
