<template>
  <div class="flex flex-col">
    <div class="text-2xl text-left my-2">{{ $t("Contacts.address") }}</div>
    <div class="flex flex-row row">
      <div class="mr-2"><font-awesome-icon icon="envelope" /></div>
      <div>{{ info.email }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactInfo",
  props: {
    info: Object,
  },
};
</script>

<style scoped>
.row {
  margin: 2px;
  font-size: 0.8rem;
}
</style>
