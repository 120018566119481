<template>
  <div class="bg-white dark:bg-gray-400 cta">
    <div class="container m-auto">
      <section class="download p-5">
        <div class="flex flex-row justify-center items-center header text-3xl">
          {{ $t("Download.download_our_pdf") }}
          <div
            class="
              download_btn
              flex flex-wrap
              p-2
              ml-8
              justify-center
              items-center
              text-gray-50
              bg-gray-500
              border-gray-500
              rounded-sm
              text-lg
            "
            @click="download"
          >
            {{ $t("Download.download") }}
          </div>
        </div>
        <app-modal
          :modalIsOpen="modalIsOpen"
          :closeModal="closeModal"
          :openModal="openModal"
          :modalTitle="modalTitle"
          :modalContent="modalContent"
          :closeBtn="closeBtn"
        />
      </section>
    </div>
  </div>
</template>

<script>
import AppModal from "@/components/modal/Modal";
export default {
  name: "Download",
  components: { AppModal },
  data() {
    return {
      url: "cv-apostolakis-myron.pdf",
      modalIsOpen: false,
      modalTitle: "Notification",
      modalContent: "",
      closeBtn: "OK",
    };
  },
  methods: {
    download() {
      try {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = this.url;

        // the filename you want
        a.download = "cv-apostolakis-myron.pdf";
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(this.url);
        this.modalContent = "Your file has downloaded!";
        this.modalIsOpen = true;
      } catch (e) {
        this.modalContent = "Something went wrong. Error:" + e.toString();
        this.modalIsOpen = true;
      }
    },
    closeModal() {
      this.modalIsOpen = false;
    },
    openModal() {
      this.modalIsOpen = true;
    },
  },
};
</script>

<style scoped>
.cta {
  background-color: #f8f8f8;
}
.download_btn {
  height: auto;
  width: auto;
}

.download_btn:hover {
  cursor: pointer;
  filter: brightness(150%);
}
.download {
  justify-content: space-between;
}
/* On screens that are 1536px or less, set the background color to olive */
@media screen and (max-width: 1536px) {
  .download {
    justify-content: space-around;
  }
}
</style>
