<template>
  <app-header />
  <app-slider />
  <app-about />
  <app-skills />
  <app-download />
  <app-portfolio />
  <app-join-us />
  <app-contacts />
  <app-footer />
  <app-scroll-to-top />
</template>

<script>
import AppHeader from "@/components/Header.vue";
import AppFooter from "@/components/footer/Footer.vue";
import AppAbout from "@/components/about/About";
import AppSlider from "@/components/slider/Slider.vue";
import AppSkills from "@/components/skills/Skills.vue";
import AppDownload from "@/components/download/Download.vue";
import AppScrollToTop from "@/components/scrollToTop/ScrollToTop.vue";
import AppPortfolio from "@/components/portfolio/Works.vue";
import AppJoinUs from "@/components/join_us/JoinUs.vue";
import AppContacts from "@/components/contacts/Contacts.vue";

export default {
  name: "Base",
  components: {
    AppHeader,
    AppFooter,
    AppAbout,
    AppSlider,
    AppSkills,
    AppDownload,
    AppScrollToTop,
    AppPortfolio,
    AppJoinUs,
    AppContacts,
  },
  props: {},
};
</script>
