export const data = {
  info: {
    street: "100 Limpbiscayne Blvd. (North) 17st Floor",
    area: "New World Tower New York",
    city: "New York",
    country: "USA",
    zip: "33148",
    tel: "1 -234 -456 -7890",
    fax: "1 -234 -456 -7890",
    email: "myapos@gmail.com",
    skype: "myapost",
  },
  form_fields: [],
};
