<template>
  <div class="bg-white dark:bg-gray-200" id="about">
    <div class="container m-auto">
      <section class="about p-5 text-center">
        <div class="header text-3xl md:text-lg">{{ $t("About.title") }}</div>
        <app-ruler name="information" />
        <div class="teaser">{{ $t("About.subtitle") }}</div>
        <section class="cards grid grid-cols-2 md:grid-cols-1 gap-4 m-5">
          <app-card
            :header="$t('About.who_we_are')"
            :text="$t('About.who_am_i_descr')"
            icon="users"
          />
          <app-card
            :header="$t('About.what_we_do')"
            :text="$t('About.what_we_do_descr')"
            icon="headset"
          />
          <app-card
            :header="$t('About.why_we_do_it')"
            :text="$t('About.why_we_do_it_descr')"
            icon="heart"
          />
          <app-card
            :header="$t('About.since_when')"
            :text="$t('About.since_when_descr')"
            icon="clock"
          />
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import AppCard from "./Card.vue";
import AppRuler from "@/shared/ruler/Ruler.vue";

export default {
  name: "About",
  components: {
    AppCard,
    AppRuler,
  },
};
</script>

<style scoped>
.header {
  text-transform: uppercase;
}
.subsection {
  max-width: 400px;
  margin: 0 auto;
}

.cards {
  justify-content: space-around;
}
</style>
