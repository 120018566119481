export const tabs = [
  "Works.all",
  "Works.APIs",
  "Works.dashboards",
  "Works.themes",
  "Works.plugins",
  "Works.awards",
  "Works.websites",
];

export const categories = {
  "Works.dashboards": [
    {
      title: "Music tools",
      subtitle: "Svelte",
      Demo: "https://music-tools.vercel.app/",
      background_image: require("@/assets/works/music_tools.png"),
    },
    {
      title: "Studying Quality ",
      subtitle: "React & Express",
      Demo: "https://studying-quality.oncrete.gr/",
      background_image: require("@/assets/works/studying_quality.png"),
    },
    {
      title: "ClientManager ",
      subtitle: "Java SpringBoot - React",
      Demo: "https://github.com/myapos/ClientManagerSpringBoot",
      background_image: require("@/assets/works/dashboards-icon-10.jpg"),
    },
  ],
  "Works.plugins": [
    {
      title: "MaxCSS",
      subtitle: "Wordpress",
      Demo: "https://wordpress.org/support/plugin/max-css/reviews/",
      background_image: require("@/assets/works/maxCSS.png"),
    },
    {
      title: "progress-bar-for-react",
      subtitle: "React - NPM",
      Demo: "https://github.com/myapos/progress-bar-for-react",
      background_image: require("@/assets/works/progress-bar-for-react.png"),
    },
  ],
  "Works.themes": [
    {
      title: "IRIS",
      subtitle: "Vue",
      Demo: "https://iristheme.netlify.app/",
      background_image: require("@/assets/works/iris.png"),
    },
  ],
  "Works.awards": [
    {
      title: "OliveOilAdmin",
      subtitle: "ELLAK",
      Link: "https://github.com/myapos/OliveOilAdmin",
      background_image: require("@/assets/works/dashboards-icon-10.jpg"),
    },
  ],
  "Works.websites": [
    {
      title: "codebitshub.com",
      subtitle: "A blog about programming and coding",
      Demo: "https://codebitshub.com/",
      background_image: require("@/assets/works/codebitshub.png"),
    },
  ],
  "Works.APIs": [
    {
      title: "Dog Breeds ",
      subtitle: "NodeJs & Express",
      Demo: "https://rapidapi.com/myapos--FqlEzvrlv/api/dog-breeds2",
      background_image: require("@/assets/works/dog_breeds.png"),
    },
    {
      title: "Fish Species ",
      subtitle: "NodeJs & Express",
      Demo: "https://rapidapi.com/myapos--FqlEzvrlv/api/fish-species/",
      background_image: require("@/assets/works/fish_species.png"),
    },
    {
      title: "Cat Breeds ",
      subtitle: "NodeJs & Express",
      Demo: "https://rapidapi.com/myapos--FqlEzvrlv/api/cat-breeds/",
      background_image: require("@/assets/works/cat_breeds.png"),
    },
  ],
};
